<template>
  <div class="relative h-full">
    <div class="grid-content h-full">
      <a-table
        :columns="props.columns"
        :data-source="props.data"
        :row-selection="props.checkbox ? rowSelection : null"
        :rowKey="props.keyField"
        :customRow="handleEventRow"
        :empty-text="props.customEmptyText"
        :scroll="props.scrollGrid"
        :pagination="false"
        size="small"
        :row-class-name="(_record, index) => (props.isRowInActive && _record['status'] == 0 ? 'row-disconnect' : null)"
        class="h-grid">
        <template #bodyCell="{ record, column }">
          <template v-if="column.dataIndex === 'action'">
            <slot
              name="widget"
              :data="record"></slot>
          </template>
          <template v-else-if="column.dataIndex === 'template' || column.template">
            <div v-if="column.status">
              <slot
                name="template"
                :data="record"
                :column="column"
                :status="record[`${column.dataIndex}`]"></slot>
            </div>
            <div v-else>
              <slot
                name="template"
                :data="record"
                :column="column"></slot>
            </div>
          </template>
          <div
            v-if="column.dataIndex == 'sshUser'"
            :title="record[`${column.dataIndex}`]">
            {{ record['localIp'] }}
          </div>
          <div v-if="column.dataIndex === 'wifiInfo'">
            <div v-if="record['wifiInfo']">
              <span>{{record['wifiInfo'].ssid}}</span>
              <span v-bind:style="{ color: getColorBySignal(record['wifiInfo'].signal) }">
              ({{record['wifiInfo'].signal}} db)
              </span>
            </div>
            <div v-else>
              N/A
            </div>
          </div>

          <div v-if="column.formatType == _enum.FormatType.Date">
            {{ $filters.formatDate(record[`${column.dataIndex}`]) }}
          </div>
          <div v-if="column.formatType == _enum.FormatType.Time">
            {{ $filters.formatDateTimeCustom(record[`${column.dataIndex}`]) }}
          </div>
          <div v-if="column.dataIndex == 'tempe'">
            <div
              v-if="record[`${column.dataIndex}`] >= 80"
              style="color: #f56c6c">
              {{ record[`${column.dataIndex}`] }}
            </div>
            <div
              v-else
              style="color: #67c23a">
              {{ record[`${column.dataIndex}`] }}
            </div>
          </div>
          <div v-if="column.isPercent">{{ record[`${column.dataIndex}`] }}%</div>
          <div
            v-if="column.dataIndex == 'deviceName'"
            :title="record[`deviceId`]"
            class="cursor-pointer">
            {{ record[`${column.dataIndex}`] || record['deviceId'] }}
          </div>
          <div v-if="column.dataIndex == 'workerVersion'">
            {{ record['androidVersion'] || 'N/A' }}-{{ record[`${column.dataIndex}`] }}
          </div>
        </template>
      </a-table>
    </div>
    <div
      class="pagination"
      v-if="pagination">
      <h-pagination
        :disabled="props.disabledPagination"
        :pageIndex="props.dataPagination.pageIndex"
        :pageSize="props.dataPagination.pageSize"
        :totalRecord="props.dataPagination.totalRecord"
        :textTotal="props.textTotal"
        @change="changePagination"></h-pagination>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, watch } from 'vue'
import _enum from '../../configs/enum'
const props = defineProps({
  columns: {
    default: [],
    typeof: Array
  },
  data: {
    default: [],
    typeof: Array
  },
  pagination: {
    default: false,
    typeof: Boolean
  },
  checkbox: {
    default: false,
    typeof: Boolean
  },
  dataSource: {
    default: [],
    typeof: Array
  },
  keyField: {
    default: 'key',
    typeof: String
  },
  customEmptyText: {
    default: 'Không có dữ liệu',
    typeof: String
  },
  paginationDefault: {
    default: false,
    typeof: String
  },
  pageIndex: {
    default: 1,
    typeof: Number
  },
  pageSize: {
    default: 20,
    typeof: Number
  },
  totalRecord: {
    default: 0,
    typeof: Number
  },
  textTotal: {
    default: '',
    typeof: String
  },
  disabledPagination: {
    default: false,
    typeof: Boolean
  },
  pageSizeOptions: {
    default: ['10', '20', '30', '50', '100'],
    typeof: Array
  },
  dataPagination: {
    default: {},
    typeof: Object
  },
  isRowInActive: {
    default: false,
    typeof: Boolean
  },
  selected: {
    default: [],
    typeof: Array
  }
})
const emit = defineEmits([
  'onChange',
  'click',
  'dbClick',
  'onContextmenu',
  'onMouseenter',
  'onMouseleave',
  'onChangeSelected',
  'onSelected',
  'onSelectedAll',
  'changePagination',
  'widgetEvent'
])
const rowSelection = ref({
  checkStrictly: false,
  onChange: (selectedRowKeys, selectedRows) => {
    emit('onChangeSelected', {
      selectedRowKeys: selectedRowKeys,
      selectedRows: selectedRows
    })
  },
  onSelect: (record, selected, selectedRows) => {
    emit('onSelected', {
      record: record,
      selected: selected,
      selectedRows: selectedRows
    })
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    emit('onSelectedAll', {
      selected: selected,
      selectedRows: selectedRows,
      changeRows: changeRows
    })
  }
})

watch(
  () => props.selected,
  (newSelect) => {
    // if (!newSelect || newSelect.length <= 0) resetSelectRow();
  }
)
const getColorBySignal = (signal) => {
  if (signal < -70) {
    return '#f56c6c'
  } else {
    return '#67C23A'
  }
}

const resetSelectRow = () => {
  rowSelection.value.selectedRowKeys = []
}
const scrollGrid = ref({
  scrollToFirstRowOnChange: true
})

const handleEventRow = (dataRow) => {
  return {
    onClick: (event) => {
      emit('click', dataRow)
    }, // click row
    onDblclick: (event) => {
      emit('dbClick', dataRow)
    }, // double click row
    onContextmenu: (event) => {
      emit('onContextmenu', dataRow)
    }, // right button click row
    onMouseenter: (event) => {
      emit('onMouseenter', dataRow)
    }, // mouse enter row
    onMouseleave: (event) => {
      emit('onMouseleave', dataRow)
    } // mouse leave row
  }
}

const changePagination = (params) => {
  emit('changePagination', params)
}

const widgetEvent = () => {
  emit('widgetEvent')
}
</script>
<script>
export default {
  methods: {}
}
</script>

<style lang="scss" scoped>
.grid-content {
  height: calc(100% - 60px);
  overflow: auto;
}

.pagination {
  position: absolute;
  bottom: 0px;
  background: #eeebeb;
  width: 100%;
  height: 60px;
  align-items: center;
  display: flex;
  padding-left: 20px;
}

.ant-table-row {
  height: 32px !important;
}

.ant-table-tbody > tr > td {
  height: 32px !important;
  padding: 0 !important;
}

.ant-table-thead > tr > th {
  height: 32px !important;
  padding: 0 !important;
}

.h-grid :deep(.row-disconnect) td {
  opacity: 0.5;
}

.h-grid :deep(.row-disconnect) td {
  opacity: 0.5;
}
</style>
