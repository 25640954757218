// import globalConfig from '../config/global.js'
import _enum from '../configs/enum';
import { store } from '../store/store'
import { ModuleLog, ModuleDevice } from '@/store/module-const'
import messageNotification from "@/utils/messageNotification/messageNotification";
import env from "@/configs/env"
export default {
  connect() {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      // Kết nối đang mở và hoạt động, không cần làm gì.
      return;
    }

    this.socket = new WebSocket(env.urlWS);
    // Xử lý khi kết nối được thiết lập
    this.socket.addEventListener('open', () => {
      console.log(`Đã kết nối máy chủ ${store.getters.userId} thành công!`);
      // Gửi yêu cầu đăng ký vào một nhóm cụ thể (ví dụ: 'task-group')
      this.socket.send(JSON.stringify({ command: 'WEB_REGISTER', data: { userId: store.getters.userId } }));

      clearInterval(this.pingIntervalId);
      this.pingIntervalId = setInterval(() => {
        this.sendPing();
        console.log(`ping...`);
      }, 10000);
    });

    // Xử lý khi nhận tin nhắn từ máy chủ
    this.socket.addEventListener('message', (event) => {
      let dataMessage = JSON.parse(event.data);
      console.log(`Received message from server:`, dataMessage);
      switch (dataMessage.command) {
        case 'DEVICE_CONNECT':
          store.dispatch(`${ModuleLog}/insertLogs`, { logType: _enum.LogSystemStatus.Connect, ...dataMessage.data });
          //store.dispatch('deviceNew', { data: dataMessage.data });
          break;
        case 'DEVICE_DISCONNECT':
          store.dispatch(`${ModuleLog}/insertLogs`, { logType: _enum.LogSystemStatus.Disconnect, ...dataMessage.data });
          //store.dispatch('deviceDisconect', { data: dataMessage.data });
          break;
        case 'DEVICE_REPORT':
          store.dispatch(`${ModuleDevice}/updateDeviceStatus`, dataMessage.data);

          break;
        case 'SERVER_MESSAGE':
          //console.log(`Received message from server:`,dataMessage);
          store.dispatch('serverMessage', { data: dataMessage.data });
          break;
        case 'DEVICE_TASK_RESULT':
          store.dispatch('taskResultMessage', { data: dataMessage.data });
          break;

        default:
        // code block
      }

    });
    // Xử lý khi kết nối đóng
    this.socket.addEventListener('close', () => {
      clearInterval(this.pingIntervalId);
      console.log(`Ngắt kết nối máy chủ ${store.getters.userId}`);
    });
  },
  send: async function (data) {
    if (this.socket) {
      await this.socket.send(JSON.stringify(data));
    }
  },
    sendPing: async function(){
      try {
          if(this.socket){
              this.socket.ping();
          }
      } catch (error) {
          //Lỗi thì thôi;
      }
  },
  disconnect() {
    if (this.socket) {
      clearInterval(this.pingIntervalId);
      this.socket.close();
    }
  },
  ChangeStatusJob: async function (devices, status) {
    const me = this;
    console.log(`Gửi lệnh chạy/tạm dừng: ${devices.length}`);
    const data = {
      command: "CHANGE_STATUS_JOB",
      data: {
        userId: store.getters.userId,
        isRunning: status,
        devices: devices.map(device => String(device.deviceId))
      }
    }
    console.log(data);
    await me.send(data);
    messageNotification.messageNotification(`Đã gửi lênh cập nhật trạng thái cho ${devices.length} thiết bị.`);
  },
  ChangeJob: async function (devices, job) {
    const me = this;
    const data = {
      command: "CHANGE_JOB",
      data: {
        userId: store.getters.userId,
        job: job,
        devices: devices.map(device => String(device.deviceId))
      }
    }
    console.log(data);
    await me.send(data);
    messageNotification.messageNotification(`Đã gửi lệnh chạy "${job.jobName}" cho ${devices.length} thiết bị`);
  },
  rebootDevice: async function (devices) {
    const me = this;
    const data = {
      command: "SEND_TASK",
      data: {
        userId: store.getters.userId,
        command: `git -C $HOME/mnworker pull && su -c "reboot"`,
        devices: devices.map(device => String(device.deviceId))
      }
    }
    await me.send(data);
    const dataUpdate = devices.map(device => ({
      deviceId: device.deviceId,
      statusMessage: "Đang khởi động lại"
    }));
    for (const device of dataUpdate) {
      store.dispatch(`${ModuleDevice}/updateDeviceStatus`, device);
    }
    
  }
}