import { createRouter, createWebHistory } from 'vue-router';

import Page from '@/pages/Page.vue';
import Home from '@/views/home/Home.vue';
import DevicePage from '@/views/device/DevicePage.vue';
import SettingPage from '@/views/setting/SettingPage.vue';
import TaskPage from '@/views/task/TaskPage.vue';
import Login from '@/views/user/Login.vue';

import SetupMiningCoin from '@/views/setting/setupMiningCoin/SetupMiningCoin.vue';
import SetupWork from '@/views/setting/setupWork/SetupWork.vue';
import SetupScript from '@/views/setting/setupScript/SetupScript.vue';
import SetupAccount from '@/views/setting/account/SetupAccount.vue';
import Proxy from '@/views/setting/proxy/ProxyList.vue';
import CommonOption from '@/views/setting/commonOption/CommonOption.vue';

import ws from '../wsClient/index';

import { moduleStore } from '@/store/store';

const routes = [
    {
        path: '/',
        name: 'root', // Đổi tên để tránh trùng lặp
        component: Page,
        children: [
            {
                name: 'homePage', // Đổi tên route này
                path: '/home',
                component: Home,
                props: true,
                meta: { requiresAuth: true }
            },
            {
                name: 'device',
                path: '/device',
                component: DevicePage,
                props: true,
                meta: { requiresAuth: true }
            },
            {
                name: 'setting',
                path: '/setup-mining-coin',
                component: SettingPage,
                props: true,
                meta: { requiresAuth: true },
                children: [
                    {
                        name: 'setupMiningCoin',
                        path: '/setup-mining-coin',
                        component: SetupMiningCoin,
                        props: true,
                        meta: { requiresAuth: true }
                    },
                    {
                        name: 'setupScript',
                        path: '/setup-script',
                        component: SetupScript,
                        props: true,
                        meta: { requiresAuth: true }
                    },
                    {
                        name: 'setupWork',
                        path: '/setup-work',
                        component: SetupWork,
                        props: true,
                        meta: { requiresAuth: true }
                    },
                    {
                        name: 'setupAccount',
                        path: '/setup-account',
                        component: SetupAccount,
                        props: true,
                        meta: { requiresAuth: true }
                    },
                    {
                        name: 'proxy',
                        path: '/proxy',
                        component: Proxy,
                        props: true,
                        meta: { requiresAuth: true }
                    },
                    {
                        name: 'commonOption',
                        path: '/common-option',
                        component: CommonOption,
                        props: true,
                        meta: { requiresAuth: true }
                    },
                ]
            },
            {
                name: 'task',
                path: '/task',
                component: TaskPage,
                props: true,
                meta: { requiresAuth: true }
            },
        ],
        meta: { requiresAuth: true }
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    }
];

export const router = createRouter({
    history: createWebHistory(),
    routes
});

// Middleware xác thực
let entryUrl = null;
router.beforeEach((to, from, next) => {
    let userId = localStorage.getItem('userId');
    let isAuthenticated = userId;
    if (isAuthenticated) {
        moduleStore.modules['user'].state.UserId = userId;
    }
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (!isAuthenticated) {
            ws.disconnect();
            if (to.name !== "login") {
                entryUrl = to.fullPath;
                console.log(entryUrl);
            }
            next({
                path: '/login',
                name: 'login'
            });
        } else if (entryUrl) {
            let url = entryUrl;
            entryUrl = null;
            ws.connect();
            next(url);
        } else {
            ws.connect();
            next();
        }
    } else {
        ws.connect();
        next();
    }
});
