<template>
  <el-container class="w-full h-full layout-container">
    <el-aside :width="`${props.widthAside}px`" class="el-aside">
      <slot name="aside"></slot>
    </el-aside>
    <el-main>
      <slot name="content" class="el-main"></slot>
    </el-main>
  </el-container>
</template>

<script setup>
const props = defineProps({
  widthAside: {
    default: 300,
    typeof: Number
  }
})
</script>

<style lang="scss" scoped>
.layout-container .el-header {
  position: relative;
  padding-inline: 0px;
  // color: var(--el-text-color-primary);
}
.layout-container .el-aside {
  // color: var(--el-text-color-primary);
}
.layout-container .el-menu {
  border-right: none;
}
.layout-container .el-main {
  padding: 0;
}
.layout-container .toolbar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  right: 20px;
}

</style>